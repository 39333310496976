import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
     borderLeft: '1px solid transparent',
    },
}));

const Reviews = (props) => {
    const parseMarkdownTable = (markdown) => {
      const lines = markdown.trim().split("\n");
      const headers = lines[0]
        .split("|")
        .map(h => h.trim())
        .filter(h => h);
    
      const rows = lines.slice(2).map(line =>
        line
          .split("|")
          .map(cell => cell.trim())
          .filter(cell => cell)
      );
    
      return rows.map(row => {
        const entry = {};
        headers.forEach((header, index) => {
          const value = row[index];
          entry[header] = isNaN(value) ? value : parseFloat(value); // Parse numeric values
        });
        return entry;
      });
    };
    const getCategories = (reviews) => {
      const categories = new Set();
      reviews.forEach(review => {
        const tag = review['Tags'];
        if (tag !== undefined) {
          if (!categories.has(tag)) {
            categories.add(tag);
          }
        }
      });
      return Array.from(categories);
    }

    const [filters, setFilters] = React.useState([]);
    const handleFilters = (
      event: React.ChangeEvent<HTMLInputElement>,
      filters: string[]
    ) => {
      setFilters(filters);
    };

    const reviews = useSelector(state => state.data.reviews)

    const colors = {
      'game': '#e6f7ff',       // Blue hint
      'show': '#fff3b0',       // Yellow hint
      'book': '#ffebee',       // Red hint
      'movie': '#e8f5e9',      // Green hint
      'podcast': '#f3e5f5',    // Purple hint
      'event': '#fff0f5'       // Pink hint
    };
      
    if (!reviews) {
        return <div></div>
    }
    const reviewsArray = parseMarkdownTable(reviews)
    const categories = getCategories(reviewsArray)
    const sortedReviews = reviewsArray.sort((a, b) => b.Score - a.Score)
    sortedReviews.forEach((review) => {
      review.color = colors[review['Tags']] || 'white';
    })
    // Filter reviews based on selected filters
    const filteredReviews = filters.length ? sortedReviews.filter((review) => filters.includes(review['Tags'])) : sortedReviews;
    return (
      <div>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          height: 20,
        })}
      >
        <StyledToggleButtonGroup
          size="small"
          value={filters}
          onChange={handleFilters}
          aria-label="text formatting"
        >
        {categories.map((category, index) => (
          <ToggleButton key={index} value={category} aria-label={category}>
            <Typography variant="review" sx={{color: colors[category]}}>{category}</Typography>
          </ToggleButton>
        ))}
        </StyledToggleButtonGroup>
      </Box> 
      {filteredReviews.map((review, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ flex: 1, textAlign: 'left', color: review.color}}>
            {review.Name}
          </Typography>
          <Typography sx={{ flex: 1, textAlign: 'right' }}>
            {review.Score}
          </Typography>
        </Box>
      ))}
    </div>
  )
}

export default Reviews
