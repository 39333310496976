import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'

const Notes = (props) => {
    const notes = useSelector(state => state.data.notes);
    if (!notes) {
        return <div></div>;
    }
    return (
      <Grid>
      {notes.map(({ name, uuid, date }) => (
          <Link to={`/${uuid}`} key={date} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '4px' }}>
            <Typography sx={{ fontSize: '9px', color: 'grey', flexShrink: 0, marginRight: '8px' }}>{date}</Typography>
            <Typography>{name}</Typography>
            </Box>
          </Link>
      ))}
      </Grid>
    )
}

export default Notes
