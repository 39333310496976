import * as React from 'react'
import { useSelector } from 'react-redux'
import GitHubIcon from '@mui/icons-material/GitHub';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    IconButton,
    Stack,
} from '@mui/material'

import CV from './cv.pdf';
//import Page from './Page';
import Markdown from 'react-markdown';

const About = (props) => {
  const about = useSelector(state => state.data.about)

  if (!about) {
    return <div></div>;
  }

  return (
    <div>
      <Markdown>{about}</Markdown>
      <Stack direction="row" justifyContent="center">
        <IconButton 
          aria-label="GitHub"
          onClick={() => window.open("https://github.com/nponte", "_blank")} 
          color="inherit"
          edge="end"
        >
          <GitHubIcon/>
        </IconButton>
        <IconButton 
          aria-label="CV"
          onClick={() => window.open(CV)}
          color="inherit"
          edge="end"
        >
          <PictureAsPdfIcon/>
        </IconButton>
      </Stack>
    </div>
  );
}

export default About;
