import React, { useMemo, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'


const planetGap = 500
const planets = ['tie', 'folder', 'cup', 'backpack', 'keyboard'];

const Planet = (props) => {
  const { nodes } = useGLTF(props.obj);
  const ref = useRef();
  const rotation_speed = Math.random() * 0.01;
  const radius_speed = Math.random() * 0.2;
  const radius = planetGap * (props.index + 1);
  const offset = Math.random() * 2 * Math.PI;
  const inclination = Math.random() * Math.PI / 3;

  useFrame(({ clock }) => {
    ref.current.rotation.y += rotation_speed;
    ref.current.rotation.x += rotation_speed;
    ref.current.rotation.z += rotation_speed;
    ref.current.position.set(
    	Math.cos((clock.getElapsedTime() * radius_speed) + offset) * radius,
    	Math.sin((clock.getElapsedTime() * radius_speed) + offset) * radius * Math.sin(inclination),
    	Math.sin((clock.getElapsedTime() * radius_speed) + offset) * radius * Math.cos(inclination),
    );
  });

  return (
    <mesh
      ref={ref}
      scale={30}
      geometry={nodes.item.geometry}>
    </mesh>
  );
};

const SolarSystem = (props) => {
  return (
    <React.Fragment>
      {planets.map((obj, index) => (
        <React.Suspense key={index} fallback={null}>
          <Planet 
            obj={`/meshes/${obj}.glb`}
            index={index}/>
        </React.Suspense>
      ))}
    </React.Fragment>
  );
};


function Stars({ count = 20000 }) {
  const positions = useMemo(() => {
    let positions = []
    for (let i = 0; i < count; i++) {
      const r = 4000
      const theta = 2 * Math.PI * Math.random()
      const phi = Math.acos(2 * Math.random() - 1)
      const x = r * Math.cos(theta) * Math.sin(phi) + (-2500 + Math.random() * 5000)
      const y = r * Math.sin(theta) * Math.sin(phi) + (-2500 + Math.random() * 5000)
      const z = r * Math.cos(phi) + (-1000 + Math.random() * 2000)
      positions.push(x)
      positions.push(y)
      positions.push(z)
    }
    return new Float32Array(positions)
  }, [count])
  return (
    <points>
      <bufferGeometry>
        <bufferAttribute attach="attributes-position" count={positions.length / 3} array={positions} itemSize={3}/>
      </bufferGeometry>
      <pointsMaterial size={8} sizeAttenuation color="white"/>
    </points>
  )
}


const Space = (props) => {
    const cam_pos = [1000 + Math.random() * 3000, 1000 + Math.random() * 3000, 1000 + Math.random() * 3000];
    const orbit_tar = [0, 0, 0];

    return (
      <Canvas camera={{position:cam_pos, near:.1, far:100000}}>
        <OrbitControls target={orbit_tar} maxDistance={4000} minDistance={1}/>
        <Stars />
        <SolarSystem />
      </Canvas>
    )
};

export default Space;
