import { configureStore, createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'data',
  initialState: {},
  reducers: {
    setData: (state, action) => action.payload,
  },
});

const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
  },
})

export const { setData } = dataSlice.actions
export default store
