import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Typography, Box } from '@mui/material'
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { synthwave84 } from 'react-syntax-highlighter/dist/esm/styles/prism'

const Page = (props) => {
  const doc = useParams().doc;
  const notes = useSelector(state => state.data.notesMap);
  if (!notes) {
    return <div/>
  }
  const data = notes[doc].content
  console.log(data)
  const title = notes[doc].name
  const date = notes[doc].date

  return (
    <div>
      <Box>
        <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography sx={{ fontSize: '8px', color: 'grey', marginBottom: '1rem' }}>{date}</Typography>
      </Box>
      <Markdown
        children={data}
        components={{
          p: ({ node, ...props }) => {
            return (
              <p style={{ fontSize: '14px', lineHeight: '1.725' }} {...props} />
            );
          },
          blockquote: ({ node, ...props }) => {
            return (
              <blockquote style={{ color: '#A8A8A8' }} {...props} />
            );
          },
          a: ({ node, ...props }) => {
            return (
              <a style={{ color: '#ADD8E6' }} {...props} />
            );
          },
          code(props) {
            const {children, className, node, ...rest} = props
            const match = /language-(\w+)/.exec(className || '')
            const language = match ? match[1] : 'plaintext'
            return (
              <SyntaxHighlighter
                language={language.toLowerCase()}
                style={synthwave84}
                customStyle={{ padding: '.5rem', borderRadius: '10px', fontSize: '12px' }}
                PreTag="div"
                {...rest}>
                {children}
              </SyntaxHighlighter>
            )
          }
        }}
      />
    </div>
  )
}

export default Page
