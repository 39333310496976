import React from 'react'
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Box,
  Container,
  Paper,
} from '@mui/material'
import { setData } from './store';
import { useDispatch } from 'react-redux';

import Space from './Space'
import About from './About'
import Notes from './Notes'
import Page from './Page'
import Reviews from './Reviews'
import Navigation from './Navigation'

const pageTheme = createTheme({
  typography: {
    fontFamily: '"Oxygen", monospace',
    logo: {
      fontFamily: '"Radar"',
      fontSize: '20px',
    },
    review: {
      fontFamily: '"Radar"',
      fontSize: '11px',
    },
  },
  palette: {
    mode: 'dark',
  },
  components: {
    MuiButtonBase: { 
      defaultProps: {
          disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        paddingLeft: '16px',
      },
    }
  },
})

const MainContent = () => {
    const location = useLocation()

    return (
      <>
        {location.pathname === '/' ? (
          <div></div>
        ) : (
          <Paper sx={{ padding: '2rem' , margin: '1rem' }}>
            <Routes>
              <Route path='/' element={<div/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/notes' element={<Notes/>} />
              <Route path='/reviews' element={<Reviews/>} />
              <Route path='/:doc' element={<Page/>} />
            </Routes>
          </Paper>
        )}
      </>
    )
}

const fetchFile = async (url) => {
  const response = await fetch(url)
  if (!response.ok) {
    return {}
  }
  return response.text()
};

const App = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [reviews, about, notes] = await Promise.all([
            fetchFile('/REVIEWS'),
            fetchFile('/ABOUT'),
            fetchFile('/NOTES').then(JSON.parse),
          ]);
          const notesWithContent = await Promise.all(
            notes.map(async (note) => {
              const content = await fetchFile(`/notes/${note.uuid}`);
              return { ...note, content };
            })
          )
          const notesMap = {}
          notesWithContent.forEach(note => {
            notesMap[note.uuid] = note
          })
          const data = {
            notes: notesWithContent,
            notesMap,
            reviews,
            about,
          };
          dispatch(setData(data));
        } catch (error) {
          console.error("Error fetching notes and about:", error);
        }
      };
  
      fetchData();
    }, [dispatch]);

    return (
      <ThemeProvider theme={pageTheme}>
        <CssBaseline/>
        <Box
         sx={{
           position: 'fixed',
           zIndex: '-1',
           height: '100%',
           width: '100%',
           backgroundColor: 'black',
         }}>
          <Space/>
        </Box>
        <Router>
          <Navigation />
          <Container maxWidth="md">
            <Routes>
              <Route path='*' element={<MainContent/>} />
            </Routes>
          </Container>
        </Router>
      </ThemeProvider>
    )
}

export default App
